import { Button, ButtonProps } from '@launchpad-ui/components';

type DestructiveButtonProps = Omit<ButtonProps, 'kind'>;

const DestructiveButton = ({ children, ...other }: DestructiveButtonProps) => (
  <Button {...other} variant="destructive">
    {children}
  </Button>
);

export { DestructiveButton };
