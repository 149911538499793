import { type JSX, Component } from 'react';
import { isFunction } from '@gonfalon/es6-utils';
import { CopyToClipboard } from '@gonfalon/launchpad-experimental';
import { type ButtonProps, Button } from '@launchpad-ui/components';
import { Icon } from '@launchpad-ui/icons';
import classNames from 'clsx';
import { Checkbox, Modal, ModalBody, ModalFooter, ModalHeader, RequiredAsterisk, TextField } from 'launchpad';

import { FormGroup } from 'components/ui/forms';
import Logger from 'utils/logUtils';

import './styles.css';

const logger = Logger.get('Confirmation');

export type ConfirmationProps = {
  onConfirm?: (data?: FormData & number) => void;
  onCancel?: () => void;
  title: string;
  description?: string | JSX.Element;
  asyncBody?:
    | string
    | JSX.Element
    | (({ onReady, focusRef }: { onReady?: () => void; focusRef?: React.Ref<HTMLElement> }) => JSX.Element);
  body?: string | JSX.Element | (() => JSX.Element);
  form?: React.ElementType;
  formProps?: {};
  confirmLabel: string;
  abortLabel?: string;
  validation?: { placeholder?: string; onValidate: (input: string) => boolean; label: string };
  testId?: string;
  kind?: ButtonProps['variant'];
  canConfirm?: boolean;
  focusBody?: boolean;
  acknowledgementText?: string;
  className?: string;
  copyableValue?: string;
};

type StateProps = {
  isBodyReady: boolean;
  isTestValid?: boolean | string;
  isFormValid: boolean;
  acknowledged: boolean;
  formValue?: FormData & number;
};

export class Confirmation extends Component<ConfirmationProps, StateProps> {
  validationInputElement: HTMLInputElement | null = null;
  confirmElement: HTMLButtonElement | null = null;
  cancelElement: HTMLButtonElement | null = null;
  bodyFocusElement: HTMLElement | null = null;
  formFocusElement: HTMLElement | null = null;

  static defaultProps = {
    abortLabel: 'Cancel',
    kind: 'default',
    validation: null,
    canConfirm: true,
    focusBody: false,
  };

  constructor(props: ConfirmationProps) {
    super(props);
    this.state = {
      isBodyReady: !this.isAsyncBody(),
      isTestValid: !props.validation,
      isFormValid: !props.form,
      acknowledged: !props.acknowledgementText,
    };
  }

  componentDidMount() {
    !this.isAsyncBody() && this.setFocus();
  }

  componentDidUpdate(prevProps: ConfirmationProps) {
    const { acknowledgementText } = this.props;
    if (prevProps.acknowledgementText !== acknowledgementText) {
      this.setState({
        acknowledged: !acknowledgementText,
      });
    }
  }

  refHandlers = {
    validationInputElement: (node: HTMLInputElement | null) => {
      this.validationInputElement = node;
    },

    confirmElement: (node: HTMLButtonElement | null) => {
      this.confirmElement = node;
    },
    cancelElement: (node: HTMLButtonElement | null) => {
      this.cancelElement = node;
    },
    bodyFocusElement: (node: HTMLElement | null) => {
      this.bodyFocusElement = node;
    },
    formFocusElement: (node: HTMLElement | null) => {
      this.formFocusElement = node;
    },
  };

  render() {
    const { onCancel, focusBody, acknowledgementText, className, description, copyableValue } = this.props;
    const { validation, title, form: Form, confirmLabel, abortLabel, kind, testId } = this.props;
    const classes = classNames('Confirmation', `Confirmation--${kind}`, className);
    const disabled = !this.isReady();

    const readyProp = this.isAsyncBody() && this.handleBodyReady;
    const bodyProp = this.parseBodyProp();
    let bodyContent;

    if (isFunction(bodyProp)) {
      const BodyComponent = bodyProp;
      bodyContent = (
        <BodyComponent
          onReady={readyProp || undefined}
          focusRef={focusBody ? this.refHandlers.bodyFocusElement : undefined}
        />
      );
    } else {
      bodyContent = focusBody ? <div ref={this.refHandlers.bodyFocusElement}>{bodyProp}</div> : <div>{bodyProp}</div>;
    }

    return (
      <Modal
        {...this.props}
        className={classes}
        size="small"
        status={kind === 'destructive' ? 'warning' : undefined}
        cancelWithOverlayClick={false}
      >
        <ModalHeader title={title} description={description} />
        <ModalBody>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div onKeyDown={this.handleKeyDown}>
            {bodyContent}
            {Form && (
              <Form
                {...this.props.formProps}
                onChange={this.handleFormChange}
                focusRef={this.refHandlers.formFocusElement}
              />
            )}
            {this.state.isBodyReady && validation && (
              <div className="Confirmation-validation">
                <label data-test-id="confirm-label" htmlFor="confirmation-validation-text-field">
                  Confirm
                  <RequiredAsterisk />
                </label>
                <p className="Confirmation-validationLabel">{validation.label}</p>
                <TextField
                  id="confirmation-validation-text-field"
                  placeholder={copyableValue ? undefined : validation.placeholder}
                  ref={this.refHandlers.validationInputElement}
                  onChange={this.handleTestChange}
                  data-test-id="confirmation-validation-text-field"
                />
                {copyableValue ? (
                  <CopyToClipboard text={copyableValue}>
                    <Button size="small" className="Confirmation-copyable">
                      {copyableValue}
                      <Icon name="copy-clipboard" size="small" />
                    </Button>
                  </CopyToClipboard>
                ) : null}
              </div>
            )}
            {acknowledgementText && (
              <FormGroup className="Confirmation-conflict">
                <Checkbox
                  className="Conflict-acknowledgement"
                  value={this.state.acknowledged.toString()}
                  checked={this.state.acknowledged}
                  onChange={(event) => {
                    this.setState({
                      acknowledged: event.target.checked,
                    });
                  }}
                >
                  {acknowledgementText}
                </Checkbox>
              </FormGroup>
            )}
          </div>
        </ModalBody>
        <ModalFooter
          secondaryButton={
            <Button onPress={onCancel} ref={this.refHandlers.cancelElement}>
              {abortLabel}
            </Button>
          }
          primaryButton={
            <Button
              ref={this.refHandlers.confirmElement}
              onPress={this.handleConfirmClick}
              variant={kind === 'default' ? 'primary' : kind}
              isDisabled={disabled}
              data-test-id={testId || 'confirm-button'}
            >
              {confirmLabel}
            </Button>
          }
        />
      </Modal>
    );
  }

  setFocus = () => {
    requestAnimationFrame(() => {
      let nodeToFocus;

      if (this.bodyFocusElement) {
        nodeToFocus = this.bodyFocusElement;
      } else if (this.formFocusElement) {
        nodeToFocus = this.formFocusElement;
      } else if (this.validationInputElement) {
        nodeToFocus = this.validationInputElement;
      } else if (this.props.kind !== 'destructive') {
        nodeToFocus = this.confirmElement;
      } else {
        nodeToFocus = this.cancelElement;
      }

      nodeToFocus && nodeToFocus.focus && nodeToFocus.focus();
    });
  };

  handleTestChange = () => {
    const input = this.validationInputElement?.value;
    this.setState({
      isTestValid: this.props.validation?.onValidate(input || ''),
    });
  };

  handleFormChange = (formValue: FormData & number, isValid: boolean) => {
    this.setState({
      isFormValid: isValid,
      formValue,
    });
  };

  handleBodyReady = () => {
    this.setState(
      {
        isBodyReady: true,
      },
      () => {
        this.setFocus();
      },
    );
  };

  handleKeyDown = (event: React.KeyboardEvent) => {
    if ((event.key === 'Enter' && (event.metaKey || event.ctrlKey)) || event.key === 'Enter') {
      if (!this.isReady()) {
        return;
      }
      this.confirm();
    }
  };

  handleConfirmClick = () => {
    if (!this.canMakeChange()) {
      return;
    }
    this.confirm();
  };

  canMakeChange() {
    const { canConfirm } = this.props;
    const { isTestValid, isFormValid } = this.state;
    return isTestValid && isFormValid && canConfirm;
  }

  isReady() {
    const { canConfirm } = this.props;
    const { isBodyReady, isTestValid, isFormValid, acknowledged } = this.state;
    return isBodyReady && isTestValid && isFormValid && canConfirm && acknowledged;
  }

  isAsyncBody() {
    return !!this.props.asyncBody;
  }

  parseBodyProp() {
    const { asyncBody, body } = this.props;

    if (asyncBody && body) {
      logger.warn('Only one of asyncBody or body should be specified on Confirmation');
    }

    return asyncBody || body;
  }

  confirm() {
    this.props.onConfirm?.(this.state.formValue);
  }
}
