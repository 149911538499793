import { useState } from 'react';
import { PressEvent } from 'react-aria';
import { Button, ButtonProps } from '@launchpad-ui/components';

import { trackRequestNewSeats } from 'utils/accountUtils';

import { RequestSeatsModal } from './RequestSeatsModal';

type AnalyticsProps = {
  componentName: string;
  type?: 'link' | 'button';
};

export type RequestMembersControlProps = {
  analyticsProps: AnalyticsProps;
  buttonProps?: ButtonProps;
  cta?: string;
};

const RequestSeatsControl = ({ cta = 'Request seats', buttonProps, analyticsProps }: RequestMembersControlProps) => {
  const [showModal, setShowModal] = useState(false);
  const { componentName, type = 'button' } = analyticsProps;

  const openModal = (e: PressEvent) => {
    trackRequestNewSeats({
      url: window.location.href,
      component: componentName,
      type,
    });
    setShowModal(true);
    buttonProps?.onPress?.(e);
  };

  const renderModal = () => {
    if (showModal) {
      return <RequestSeatsModal onCancel={() => setShowModal(false)} />;
    }
  };

  return (
    <>
      <Button variant="default" {...buttonProps} onPress={openModal}>
        {cta}
      </Button>
      {renderModal()}
    </>
  );
};

/* eslint-disable import/no-default-export */
export default RequestSeatsControl;
