import { Suspense, useEffect, useState } from 'react';
import { PressEvent } from 'react-aria';
import { useMatch, useNavigate } from 'react-router';
import { enableSuggestInvites } from '@gonfalon/dogfood-flags';
import { toMembers } from '@gonfalon/navigator';
import {
  Alert,
  Button,
  ButtonProps,
  Dialog,
  DialogTrigger,
  Heading,
  IconButton,
  Modal,
  ModalOverlay,
  Perceivable,
  ProgressBar,
  Text,
  Tooltip,
  TooltipTrigger,
} from '@launchpad-ui/components';
import { Box } from 'launchpad';

import RequestSeatsControl from 'components/requestSeats/RequestSeatsControl';
import { useSuggestInviteMembersModal } from 'hooks/useSuggestInviteMembersModal';
import { trackAddMembersList, trackInviteMembersModalOpened } from 'utils/accountUtils';

import { InviteMembersForm } from './InviteMembersForm';
import { useCanInviteMembers } from './useCanInviteMembers';

export type InviteMembersModalProps = {
  analyticsProps?: {
    componentName: string;
    type: 'button' | 'link';
    referrer: string;
  };
  buttonProps?: ButtonProps;
  openModalOnRender?: boolean;
};

export function InviteMembersModal({
  analyticsProps,
  buttonProps = {},
  openModalOnRender = false,
}: InviteMembersModalProps) {
  const navigate = useNavigate();
  const isMembersInviteLocation = useMatch('/settings/members/invite');

  const { onPress } = buttonProps;
  const isSuggestInvitesEnabled = enableSuggestInvites();

  const [isOpen, setIsOpen] = useState(openModalOnRender);
  const { canInviteMembers, renderRequestSeats, shouldDisableButton, disabledReasons, hasSSO } = useCanInviteMembers();

  // todo - rebuild this at some point
  const { openModal: openSuggestInvitesModal } = useSuggestInviteMembersModal();

  useEffect(() => {
    // todo - for non-enterprise, support the showBulkInviteMembers param (invite after purchase feature)
    trackInviteMembersModalOpened();
  }, []);

  const handleOpenChange = async (nextIsOpen: boolean) => {
    if (isSuggestInvitesEnabled && !canInviteMembers) {
      openSuggestInvitesModal({ referrer: analyticsProps?.referrer ?? 'InviteMembersModal' });
      return;
    }

    if (nextIsOpen === false && isMembersInviteLocation) {
      await navigate(toMembers());
    }

    // have to prevent the modal from opening if the button is disabled since we're using perceivable to allow for a tooltip
    if (shouldDisableButton) {
      return;
    }

    setIsOpen(nextIsOpen);
  };

  const handleButtonPress = (e: PressEvent) => {
    trackAddMembersList({
      url: window.location.pathname,
      type: 'button',
      component: analyticsProps?.componentName ?? 'InviteMembersModal',
    });

    onPress?.(e);
  };

  if (!canInviteMembers && !isSuggestInvitesEnabled) {
    return null;
  }

  if (renderRequestSeats) {
    return (
      <RequestSeatsControl
        analyticsProps={{ componentName: 'InviteMembersModal' }}
        buttonProps={{ variant: 'primary' }}
      />
    );
  }

  const renderButtonTrigger = () => {
    const button = (
      <Button variant="primary" onPress={handleButtonPress} {...buttonProps}>
        Invite members
      </Button>
    );

    if (shouldDisableButton) {
      return (
        <Perceivable>
          <TooltipTrigger>
            {button}
            <Tooltip>
              <ul>
                {disabledReasons.map((reason, idx) => (
                  <li key={idx}>{reason}</li>
                ))}
              </ul>
            </Tooltip>
          </TooltipTrigger>
        </Perceivable>
      );
    }

    return button;
  };

  return (
    <DialogTrigger isOpen={isOpen} onOpenChange={handleOpenChange}>
      {renderButtonTrigger()}
      <ModalOverlay>
        <Modal>
          <Dialog>
            <div slot="header">
              <Heading slot="title">Invite members</Heading>
              <IconButton
                aria-label="close modal"
                icon="cancel"
                variant="minimal"
                onPress={() => handleOpenChange(false)}
              />
            </div>
            <div slot="body">
              {hasSSO && (
                <Box marginBottom="$600">
                  <Alert status="warning">
                    <Text slot="subtitle">
                      Adding members through this modal when SSO is enabled will have undesireable results. Please use
                      the SAML SSO flow to add members.
                    </Text>
                  </Alert>
                </Box>
              )}

              <Suspense
                fallback={
                  <Box display="flex" justifyContent="center" alignItems="center">
                    <ProgressBar aria-label="Loading…" isIndeterminate size="small" />
                  </Box>
                }
              >
                <InviteMembersForm onCancel={() => handleOpenChange(false)} />
              </Suspense>
            </div>
          </Dialog>
        </Modal>
      </ModalOverlay>
    </DialogTrigger>
  );
}
